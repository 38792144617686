nav.navbar-dark {
    background: rgba(44,45,44,1);
    width: 100%;
    margin-top: 1rem;

    &.navbar {
        padding: 0;
    }

    button.navbar-toggler {
        color: white;
        border: 0;
        border-radius: 0;
        width: 100%;
    }

    ul.navbar-nav {
        width: 100%;

        li {
            a.nav-link {
                color: white;
                &:hover {
                    background: #ed7721;
                    color: white;
                }
            }
            &.active {
                a {
                    background: #ed7721;
                }
            }
        }
    }
}

a.nav-link {
    padding: 1rem 1rem !important;
    font-weight: bold;
    text-decoration: none;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    a.nav-link {
        padding: 1.5rem 1.5rem !important;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    a.nav-link {
        padding: 1.5rem 2rem !important;
    }
}
