#footer {
    background: rgba(44,45,44,1);
    color: white;
    margin: 0;

    #footer-logo {
        margin: 1rem 0;
    }

    #footer-links-container {
        ul {
            height: 100%;
            list-style: none;
            padding-left: 0;
            display: flex;
            flex-direction: row;
            margin-bottom: 0;
            li {

                a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    color: white;
                    text-decoration: none;
                    padding: 0 2rem;
                    font-weight: bold;
                    height: 100%;

                    &:hover {
                        background: #ed7721;
                    }

                    &.active {
                        background: #ed7721;
                    }
                }
            }
        }
    }
}
