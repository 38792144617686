#contactWithMapAreaBrick {
    background: #f0f0f0;
    display: flex;
    flex-direction: row-reverse;
    margin: 0;

    #contactWithMapAreaBrick-contact {
        padding-left: 3rem;

        #contactWithMapAreaBrick-address, #contactWithMapAreaBrick-phone, #contactWithMapAreaBrick-fax, #contactWithMapAreaBrick-email {
            display: flex;
            margin-top: 2rem;
            i {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #2c2d2c;
                color: white;
                height: 3rem;
                width: 3rem;
            }
            div.contactWithMapAreaBrick-text {
                display: flex;
                margin-left: 4rem;
                align-items: center;
                height: 3rem;
                font-weight: bold;
                font-size: 1.3em;

                a {
                    color: inherit;
                }
            }
        }

        #contactWithMapAreaBrick-address {
            margin-top: 4rem;
            div.contactWithMapAreaBrick-text {
                align-items: baseline;
                font-weight: normal;
                font-size: 1em;
                height: auto;
                flex-direction: column;
            }
        }

        #contactWithMapAreaBrick-email {
            margin-bottom: 2rem;
        }
    }

    #contactWithMapAreaBrick-map {
        padding: 0;

        h3 {
            margin-top: 4rem;
        }
        .contactWithMapAreaBrick-mapContainer {
            width: 100%;
            height: 500px;
        }
    }
}
