@import "~bootstrap/scss/bootstrap";
@import "~@fontsource/raleway";

body {
    background-color: white;
    font-family: 'Raleway', sans-serif;
}

picture > img {
    width: 100%;
    height: auto;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

@import "header";
@import "topNav";
@import "footer";

@import "AreaBrick/contactWithMap";
@import "AreaBrick/textOnly";
@import "AreaBrick/imageBannerWithTeaser";
@import "AreaBrick/textBild";
