#quickcontact {
    text-align: right;
    margin: auto 0;
    font-weight: bold;

    #quickcontact-phone, #quickcontact-mail {
        display: inline-block;
    }

    #quickcontact-addr {
        display: none;
    }

    & a {
        color: inherit;
        &:hover {
            text-decoration: underline;
        }
    }
    i {
        color: #ed7721;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
    #quickcontact {
        display: block;

        #quickcontact-phone, #quickcontact-mail {
            display: inline-block;
        }
        #quickcontact-addr {
            display: block;
        }
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    #quickcontact {
        #quickcontact-addr {
            display: inline-block;
        }
    }
}
