.area-image-banner-with-teaser-text {
    .aibwtt-main {
        background: center center/cover no-repeat;

        .container {
            h1 {
                padding-top: 4rem;
                font-weight: bold;
                font-size: 3.5rem;
            }
            a.btn {
                margin-top: 2rem;
                color: white;
                background: #2c2d2c;
                border-radius: 0;
                padding: 1rem 5rem 1rem 3rem;
                position: relative;

                &:after {
                    content: "\f30b";
                    font-family: "Font Awesome 5 Free";
                    font-style: normal;
                    font-weight: 900;
                    position: absolute;
                    right: 1em;
                }
            }
        }
    }
}
