.area-text-bild {
    padding-top: 2rem;
    padding-bottom: 2rem;

    &.grey {
        background: #f0f0f0;
    }

    &>div>div {
        display: flex;

        &.reverse {
            flex-direction: row-reverse;
        }

        div.editmode {
            display: block;
        }

        a.btn {
            margin-top: 1rem;
            color: white;
            background: #2c2d2c;
            border-radius: 0;
            padding: 1rem 5rem 1rem 3rem;
            position: relative;

            &:after {
                content: "\f30b";
                font-family: "Font Awesome 5 Free";
                font-style: normal;
                font-weight: 900;
                position: absolute;
                right: 1em;
            }
        }

    }
}
